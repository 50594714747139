<template>
  <div :class="pageClass">
    <div class="projet_inner">
      <div class="block_projet block_projet_first">
        <div class="block_contain">
          <div class="block_header">
            <div class="block_header_inner">
              <div class="header_title font_anton">
              </div>
              <div class="close_btn" @click="closeProjet()">
                <img :src="require('@/assets/images/ui/close.png')" />
              </div>
            </div>
          </div>
          <div class="bg_video">
            <div class="video_loader_outer" v-if="iframe_video.length > 0"><div class="video_loader"></div></div>
            <div class="bg_video_wrapper" v-html="iframe_video"></div>
          </div>
          <div class="block_footer">
            <div class="block_footer_inner font_bebas"></div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="block_inner">
          <div class="block_content">
            <div class="big_title font_anton" v-html="libelle"></div>
            <div class="resume font_oswald"><span class="font_anton">Résumé</span>{{resume}}</div>
          </div>
          <div class="block_2">
            <div class="block_images">
              <div class="image" v-for="(screenshot, index) in screenshots" :key="index">
                <Img :doc="cdn + '/works/' + folder + '/' + screenshot + '.jpg'" />
              </div>
            </div>
            <div class="block_infos" v-if="infos_left.length > 0 || infos_right.length > 0 || infos_large.length > 0">
              <div class="block_infos_left" v-if="infos_left.length > 0">
                <ul>
                  <li v-for="(info, index) in infos_left" :key="index"><div class="li_left font_anton" v-html="info.libelle"></div><div class="li_right font_oswald" v-html="info.value"></div></li>
                  <li v-if="logo.length > 0"><div class="li_left font_anton">Diffuseur</div><div class="li_right font_oswald"><img :src="logo" /></div></li>
                </ul>
              </div>
              <div class="block_infos_right" v-if="infos_right.length > 0">
                <ul>
                  <li v-for="(info, index) in infos_right" :key="index"><div class="li_left font_anton" v-html="info.libelle"></div><div class="li_right font_oswald" v-html="info.value"></div></li>
                </ul>
              </div>
              <div class="block_infos_large" v-if="infos_large.length > 0">
                <ul>
                  <li v-for="(info, index) in infos_large" :key="index"><div class="li_left font_anton" v-html="info.libelle"></div><div class="li_right font_oswald" v-html="info.value"></div></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*

<div class="close_btn" @click="closeProjet()">
                <div class="close_btn_inner">
                  <a class="font_oswald">{{back}}</a>
                </div>
              </div>

<img :src="cdn + '/works/essonne/'+screenshot+'.jpg'" />

          <div class="block_other_work">
            <div class="title font_anton">Projets similaires</div>
            <div class="works">
              <Preview
                v-for="(work, index) in other_works"
                :key="index"
                :doc="work"
              />
            </div>
          </div>
*/
// import Preview from '@/components/Preview'
import Img from '@/components/Img'
import _ from 'lodash'
import { gsap, Elastic, Power4 } from 'gsap'
// import ScrollTrigger from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Projet',
  metaInfo () {
    // const type = this.$route.matched[0].name
    const self = this
    const typeProjet = this.$route.path
    const explodeType = typeProjet.split('/')
    const what = explodeType[1].charAt(0).toUpperCase() + explodeType[1].slice(1)
    const nom = explodeType[2].split('-')
    let finalNom = ''
    for (let i = 0; i < nom.length; i++) {
      finalNom += nom[i][0].toUpperCase() + nom[i].substr(1) + ' '
    }
    console.log('META INFO')
    const projet = _.find(this.$store.state.works, function (w) { return w.display === self.$route.params.id })
    const description = projet.resume
    // const description = 'toto'
    // console.log(projet)
    return {
      title: this.$store.state.meta.basic + ' | ' + what + ' | ' + finalNom.trim(),
      meta: [
        { name: 'robots', content: this.$store.state.meta.robots },
        { name: 'description', content: description + ' ' + this.$store.state.meta.description },
        { name: 'keywords', content: finalNom.toLowerCase().trim() + ', ' + this.$store.state.meta.keywords },
        { property: 'og:title', content: this.$store.state.meta.og_title + ' | Fiction | Nos productions' },
        { property: 'og:site_name', content: this.$store.state.meta.og_site_name },
        { property: 'og:type', content: this.$store.state.meta.og_type },
        { property: 'og:url', content: this.$store.state.meta.og_url + typeProjet },
        { property: 'og:image:type', content: this.$store.state.meta.og_image_type },
        { property: 'og:image', content: this.$store.state.meta.og_image }
      ]
    }
  },
  components: {
    Img
  },
  data () {
    return {
      pageClass: 'projet',
      page_loaded: false,
      video_loaded_from_start: this.$store.state.loading_first ? 2500 : 400,
      cdn: this.$store.state.cdn,
      libelle: '',
      resume: '',
      folder: '',
      type: '',
      other_works: [],
      screenshots: [],
      // iframe_video: '',
      infos_left: [],
      infos_right: [],
      infos_large: [],
      logo: '',
      fromRoute: ''
      // ready: false
    }
  },
  beforeRouteEnter (to, from, next) {
    console.log('INSIDE PROJET - BEFORE ROUTE ENTER')
    // this.fromRoute = from
    // if (!from.name) document.documentElement.style.overflow = 'hidden'
    window.scrollTo(0, 0)
    next(vm => {
      vm.fromRoute = from
      // vm.$store.state.loading_time_after = 1150
      vm.$store.state.loading_time_after = 0
    })
  },
  beforeRouteLeave (to, from, next) {
    console.log('PROJET - Before route leave')
    this.$store.state.loading_open = true
    this.$store.state.loading_leave = true
    // LOADING TITLE
    if (to.name === 'home') this.$store.state.loading_title = 'crazymage'
    else if (to.name === 'projet') this.$store.state.loading_title = 'work'
    else this.$store.state.loading_title = to.name
    setTimeout(function () {
      next()
    }, this.$store.state.loading_time_before)
  },
  beforeRouteUpdate (to, from, next) {
    console.log('INSIDE PROJET - BEFORE ROUTE UPDATE')
    next()
  },
  beforeCreate () {
    console.log('Projet - before created')
    // if (this.work) console.log('before - projet !')
    // else console.log('before - pas projet erreur')
  },
  created () {
    console.log('Projet - created')
    const works = this.$store.state.works
    const self = this
    console.log(this.$route.params.id)
    this.work = _.find(works, function (w) { return w.display === self.$route.params.id })
    const type = this.$route.matched[0].name
    if (!this.work) this.$router.push('/' + type)
    else {
      // Libelle
      this.libelle = this.work.libelle ? this.work.libelle : ''

      // Resule
      this.resume = this.work.resume ? this.work.resume : ''

      // INFOS LEFT AND RIGHT
      this.infos_left = this.work.info_left && this.work.info_left.length > 0 ? JSON.parse(this.work.info_left) : []
      this.infos_right = this.work.info_right && this.work.info_right.length > 0 ? JSON.parse(this.work.info_right) : []
      this.infos_large = this.work.info_large && this.work.info_large.length > 0 ? JSON.parse(this.work.info_large) : []

      // LOGO
      this.logo = this.work.logo && this.work.logo.length > 0 ? this.$store.state.cdn + '/images/logos/' + this.work.logo : ''

      // FOLDER
      this.folder = this.work.folder ? this.work.folder : ''

      // TYPE
      this.type = this.work.type ? this.work.type.toLowerCase() : ''

      // SCREENSHOTS
      if (this.work.screenshot && parseInt(this.work.screenshot) > 0) {
        for (let i = 1; i < parseInt(this.work.screenshot) + 1; i++) this.screenshots.push('screenshot_' + i)
      }
    }

    setTimeout(function () {
      window.scrollTo(0, 0)
      // self.ready = true
    }, 700)
    //  OTHERS WORKS
    //  this.other_works = _.sampleSize(_.filter(works, function (w) { return w.actif && w.type.indexOf(self.$route.matched[0].name.toLowerCase()) > -1 }), 2)
  },
  beforeDestroy () {
    console.log('Projet - before destroy')
  },
  destroyed () {
    console.log('Projet - detroyed')
  },
  computed: {
    btnDisabled () {
      // return this.$store.state.loading_open
      return false
    },
    back () {
      // const name = this.$route.matched[0].name
      // return name.charAt(0).toUpperCase() + name.slice(1)
      return 'CLOSE'
    },
    iframe_video () {
      console.log('iframe video: ' + this.work.vimeo_id)
      const videoUrl = this.work.vimeo_id && this.work.vimeo_id !== '' ? this.work.vimeo_id.toString().replace('/', '?h=') : null
      this.videoLoaded()
      return this.page_loaded && videoUrl ? '<div class="bg_video_reveal bg_video_reveal_left"></div><div class="bg_video_reveal bg_video_reveal_right"></div><iframe class="iframe_video" ref="iframe_video" src="https://player.vimeo.com/video/' + videoUrl + '" frameborder="0" allowfullscreen></iframe>' : ''
    }
    // libelle () {
    //   if (this.work.libelle) return this.work.libelle
    //   else return ''
    // }
  },
  mounted: function () {
    console.log('Projet - Mounted')
    console.log('duree video launch')
    console.log(this.video_loaded_from_start)
    const self = this
    if (!this.$store.state.loading_first) {
      setTimeout(function () {
        self.page_loaded = true
        self.$store.state.loading_open = false
        self.openCloseBtn()
      }, this.$store.state.loading_time_after)
    } else {
      this.page_loaded = true
      this.$store.state.loading_open = false
      // alert('ici')
      setTimeout(function () { self.openCloseBtn() }, 3000)
    }

    // const self = this
    // self.pageClass = 'projet show'
    // setTimeout(function () {
    //   self.pageClass = 'projet show'
    // }, 50)
    // setTimeout(function () {
    //   self.page_loaded = true
    // }, 800)
    // VIMEO
    // this.iframe_video = this.work.vimeo_id ? '<iframe class="iframe_video" src="https://player.vimeo.com/video/' + this.work.vimeo_id + '" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>' : ''
  },
  /* watch: {
    iframe_video (newIframe, oldIframe) {
      console.log('ça bouge')
      console.log(newIframe)
    }
  }, */
  methods: {
    videoLoaded () {
      console.log('check video loaded')
      console.log(this.video_loaded_from_start)
      const self = this
      setTimeout(function () {
        gsap.timeline()
          .to('.bg_video_reveal_left', { width: 0, duration: 1, ease: Power4.easeInOut }, 0)
          .to('.bg_video_reveal_right', { width: 0, duration: 1, ease: Power4.easeInOut }, 0)
      }, self.video_loaded_from_start)
    },
    openCloseBtn () {
      gsap.timeline().to('.close_btn', {
        opacity: 1,
        scale: 1,
        duration: 1,
        ease: Elastic.easeOut
      }, 0)
    },
    removeCloseBtn () {
      gsap.timeline().to('.close_btn', {
        opacity: 1,
        scale: 0,
        duration: 0.8,
        ease: Elastic.easeIn
      }, 0)
    },
    closeProjet () {
      // const self = this
      // const parent = this.$route.matched[0].name

      // this.page_loaded = false
      // this.$router.go(-1)
      this.removeCloseBtn()
      console.log('ici btn close')
      console.log(this.btnDisabled)
      if (this.btnDisabled) return

      /* if (this.fromRoute && this.fromRoute.matched && this.fromRoute.matched.length > 0) {
        this.$router.go(-1)
      } else {
        this.$router.push('/' + this.type)
      } */
      this.$router.push('/' + this.type)
      // setTimeout(function () {
      //   self.pageClass = 'projet'
      // }, 50)
      // setTimeout(function () {
      //   self.$router.push({ name: parent })
      // }, 300)
    }
  }
}
</script>

<style lang="scss">

.projet {
  // transform: translateY(100%);
  // overflow: hidden;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // overflow-y: auto;
  height: 100%;
  // z-index: 999;
  background: #141414;
  padding-bottom: 60px;
  // opacity: 1;
  // transition: opacity 0.4s linear;
  &.show {
    // opacity: 1;
  }
}
// TRANSITIONS PAGE
//.projet-transition-enter-active,
//.projet-transition-leave-active {
//  opacity: 0;
//}
//.projet-transition-enter-from,
//.projet-transition-leave-to {
//  opacity: 0;
//}

.block_projet {

  position: relative;
  width:100%;
  height:auto;
  padding-bottom: 40px;
  padding-top: 80px;

  .block_contain {

    width:100%;
    height:auto;
    display:flex;
    flex-direction: column;
    position:relative;

    .block_header {
      position: fixed;
      z-index: 999;
      top: 22px;
      right: 2em; // 25
      .block_header_inner {
        text-align: right;
        //margin: 20px;
        //margin-right: 20px;
        //margin-left: 40px;
        color:#fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        .header_title {
          font-size: 3em;
          line-height: 1em;
          margin-bottom: -5px;
          letter-spacing: 0.02em;
        }
        .close_btn {
          position: relative;
          width: 30px;
          //padding-top: 2px;
          display: flex;
          cursor: pointer;
          opacity: 0;
          transform: scale(0);
          img {
            width:100%;
            height: 100%;
          }
          &:hover {
            .close_btn_inner {
              &::before, &::after {
                transform: rotate(0);
              }
              &::before {
                top: 0;
              }
              &::after {
                bottom: 0;
              }
              a {
                opacity: 1;
              }
            }
          }
          .close_btn_inner {
            width: inherit;
            text-align: center;
            a {
              font-size: 1.0em;
              line-height: 2.2em;
              //text-transform: uppercase;
              color: #fff;
              transition: all .25s;
              opacity: 0;
              cursor: pointer;
              letter-spacing: 0.05em;
            }
            &::before, &::after {
              position: absolute;
              content: '';
              height: 1px;
              width: inherit;
              background: #fff;
              left: 0;
              // -moz-transition: all .3s ease-in;
              // -webkit-transition: all .3s ease-in;
              transition: all .25s;
              -webkit-backface-visibility: hidden;
              -webkit-transform-style: preserve-3d;
              -webkit-transform: translate3d(0, 0, 0);
            }
            &::before {
              top: 50%;
              transform: rotate(45deg);
            }
            &::after {
              bottom: 50%;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
    .block_footer {
      .block_footer_inner {
        text-align: left;
        margin-left: 40px;
        height: 0px;
        color:#fff;
      }
    }

    .bg_video {

      // flex-grow: 1;
      display: flex;
      align-items: center;
      position: relative;

      .video_loader_outer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,50%);
      }
      .video_loader,
      .video_loader:before,
      .video_loader:after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: load7 1.8s infinite ease-in-out;
        animation: load7 1.8s infinite ease-in-out;
      }
      .video_loader {
        color: #333;
        font-size: 6px;
        margin-top: -2.5em;
        // margin: 80px auto;
        position: relative;
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
      .video_loader:before,
      .video_loader:after {
        content: '';
        position: absolute;
        top: 0;
      }
      .video_loader:before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      .video_loader:after {
        left: 3.5em;
      }
      @-webkit-keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }
      @keyframes load7 {
        0%,
        80%,
        100% {
          box-shadow: 0 2.5em 0 -1.3em;
        }
        40% {
          box-shadow: 0 2.5em 0 0;
        }
      }

      .bg_video_wrapper {
        height: 0;
        width: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
        padding-bottom: 56.25%;
        .bg_video_reveal {
          position: absolute;
          top: 0;
          width: 50%;
          height: 100%;
          z-index: 2;
          background: #141414;
          &.bg_video_reveal_left {
            // background: red;
            left: 0;
          }
          &.bg_video_reveal_right {
            // background: green;
            right: 0;
          }
        }
        .iframe_video {
          // position: relative !important;
          // height: auto;
          // width: 100%;
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
        }
      }
    }

  }

}

.projet {
  .main {
    max-width: 2200px;
    margin: 0 auto;
    padding-bottom: 10px;

    .block_inner {
      background-color:#141414;
      padding: 40px 25px;
      padding-top:0;
      padding-bottom: 0px;
      color: #fff;

      .block_content {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: space-between;
        .big_title {
          font-size: 3em;
          line-height: 1.2em;
          flex-basis: 100%;
          margin-top: -6px;
          // transform: translateY(-130px);
        }
        .resume {
          padding-top: 30px;
          flex-basis: 100%;
          font-size: 1.2em;
          line-height: 1.4em;
          letter-spacing: 0.05em;
          span {
            display:block;
            letter-spacing: 0.05em;
          }
        }
      }

      .block_2 {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        .block_images {
          flex-basis: 100%;
        }
        .block_infos {
          flex-basis: 100%;
        }
      }

      .block_images {
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .image {
          flex-basis: calc(100%);
          margin-bottom:3px;
          background-color: #141414;
          aspect-ratio: 16/9;
          &:first-child {
            flex-basis: calc(100%);
            margin-top: 0px;
          }

        }
      }

      .block_infos {
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        flex-wrap: wrap;
        .block_infos_left, .block_infos_right, .block_infos_large {
          ul {
            margin: 0;
            padding: 0;
            li {
              list-style-type: none;
              letter-spacing: 0.05em;
              //line-height: 1.4em;
              padding-bottom: 15px;
              //display: flex;
              .li_left {
                font-size: 1.0em;
                //padding-right: 10px;
              }
              .li_right {
                font-size: 1.0em;
                line-height: 1.3em;
                max-width: 400px;
              }
              img {
                width: 100%;
                padding-top: 5px;
                max-width: 90px;
              }
            }
          }
        }
        .block_infos_left {
          flex-basis: calc(50% - 15px);
          padding-right: 15px;
          // min-width: 190px;
        }
        .block_infos_right {
          flex-basis: 50%;
          // min-width: 190px;
        }
        .block_infos_large {
          flex-basis: 100%;
          // min-width: 190px;
        }
      }
      .block_other_work {
        padding-top: 80px;
        .title {
          text-align: center;
          font-size:2em;
          line-height: 1em;
          padding-bottom: 80px;
          letter-spacing: 0.05em;
        }
        .works {
          display: flex;
          flex-direction: row;
          .work {
            flex-basis: 50%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 42em) {
}
@media screen and (min-width: 60em) {
  .projet {
    .block_projet {
      padding-bottom: 0px;
      height:calc(100vh - 80px);
      padding-top: 80px;
      .block_contain {
        height:100%;
        flex-direction: column;
        .block_header {
          right: 2em;
          .block_header_inner {
            text-align: right;
            // margin: 20px;
            // margin-right: 40px;
            // margin-left: 40px;
            justify-content: space-between;
            .header_title {
              font-size: 3em;
              line-height: 1em;
              margin-bottom: -5px;
            }
            .close_btn {
              width: 40px;
              cursor: pointer;
              .close_btn_inner {
                a {
                  font-size: 1.0em;
                  line-height: 2.2em;
                }
              }
            }
          }
        }
        .block_footer {
          .block_footer_inner {
            height: 65px;
          }
        }
        .bg_video {
          flex-grow: 1;
          height: 100%;
          .bg_video_wrapper {
            height: 100% !important;
            padding-bottom: 0;
            .iframe_video {
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
            }
          }
        }

      }

    }
    .main {
      padding-bottom: 30px;
      .block_inner {
        padding: 40px;
        .block_content {
          flex-direction: row;
          align-content: flex-start;
          .big_title {
            font-size: 4.2em;
            line-height: 1.2em;
            flex-basis: calc(50% - 15px);
            margin-top: -6px;
          }
          .resume {
            padding-top: 0px;
            flex-basis: calc(50% - 3px);
            padding-left: 3px;
            font-size: 1.2em;
            line-height: 1.4em;
          }
        }
        .block_2 {
          flex-direction: row-reverse;
          .block_images {
            flex-basis: 50%;
            padding-top: 80px;
            flex-direction: row;
            flex-wrap: wrap;
            .image {
              flex-basis: calc(50% - 6px);
              margin:3px;
              &:first-child {
                flex-basis: calc(100% - 6px);
                margin-top: 0px;
              }
            }
          }
          .block_infos {
            flex-basis: 50%;
            padding-top: 80px;
            flex-direction: row;
            align-content: flex-start;
            .block_infos_left, .block_infos_right , .block_infos_large {
              ul {
                li {
                  padding-bottom: 15px;
                  .li_left {
                    font-size: 1.2em;
                  }
                  .li_right {
                    font-size: 1.2em;
                    line-height: 1.3em;
                  }
                }
              }
            }
            .block_infos_right {
              flex-basis: calc(55% - 15px);
              padding-right: 15px;
            }
            .block_infos_left {
              flex-basis: calc(45% - 15px);
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 72em) {
  .projet {
    .block_projet {
      padding-bottom: 0px;
      height:calc(100vh - 80px);
      padding-top: 80px;
      .block_contain {
        height:100%;
        flex-direction: column;
        .block_header {
          right: 2em;
          .block_header_inner {
            text-align: right;
            // margin: 20px;
            // margin-right: 40px;
            // margin-left: 40px;
            justify-content: space-between;
            .header_title {
              font-size: 3em;
              line-height: 1em;
              margin-bottom: -5px;
            }
            .close_btn {
              width: 40px;
              cursor: pointer;
              .close_btn_inner {
                a {
                  font-size: 1.0em;
                  line-height: 2.2em;
                }
              }
            }
          }
        }
        .block_footer {
          .block_footer_inner {
            height: 65px;
          }
        }
        .bg_video {
          flex-grow: 1;
          height: 100%;
          .bg_video_wrapper {
            height: 100% !important;
            padding-bottom: 0px;
            .iframe_video {
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
            }
          }
        }

      }

    }
    .main {
      padding-bottom: 80px;
      .block_inner {
        padding: 40px;
        .block_content {
          flex-direction: row;
          align-content: flex-start;
          .big_title {
            font-size: 5em;
            line-height: 1.2em;
            flex-basis: calc(50% - 15px);
            margin-top: -6px;
          }
          .resume {
            padding-top: 0px;
            flex-basis: calc(50% - 3px);
            padding-left: 3px;
            font-size: 1.2em;
            line-height: 1.5em;
          }
        }
        .block_2 {
          flex-direction: row-reverse;
          .block_images {
            flex-basis: 50%;
            padding-top: 80px;
            flex-direction: row;
            flex-wrap: wrap;
            .image {
              flex-basis: calc(50% - 6px);
              margin:3px;
              &:first-child {
                flex-basis: calc(100% - 6px);
                margin-top: 0px;
              }
            }
          }
          .block_infos {
            flex-basis: 50%;
            padding-top: 80px;
            flex-direction: row;
            align-content: flex-start;
            .block_infos_left, .block_infos_right, .block_infos_large {
              ul {
                li {
                  padding-bottom: 15px;
                  .li_left {
                    font-size: 1.2em;
                  }
                  .li_right {
                    font-size: 1.2em;
                    line-height: 1.2em;
                  }
                }
              }
            }
            .block_infos_right {
              flex-basis: calc(55% - 15px);
              padding-right: 15px;
            }
            .block_infos_left {
              flex-basis: calc(45% - 15px);
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 110em) {

}

</style>
